<template>
  <v-card
    flat
    :height="height"
    :max-height="maxHeight"
    :max-width="maxWidth"
    class="d-flex justify-center grey lighten-2 mx-auto flex-column"
  >
    <v-icon class="grey--text">mdi-video</v-icon>
    <v-card-text class="text-no-wrap text-truncate text-center">{{
      value.name
    }}</v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 150
    },
    width: {
      default: '100%'
    },
    maxHeight: { default: '75px' },
    maxWidth: { default: null },
    value: {
      type: null,
      default: ''
    }
  },
  data() {
    return {
      innerValue: '',
      loading: false
    }
  }
}
</script>
