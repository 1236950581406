<template>
  <div v-if="model && !isLoading">
    <app-toolbar :crumbs="crumbs" />

    <div>
      <material-form
        v-model="model"
        :collection="collection"
        @cancel="onCancel"
        @save="onSave"
      ></material-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import collectionApi from '@/services/api/collection'
import materialApiBuilder from '@/services/api/material'
import materialForm from './form'

export default {
  components: { appToolbar, materialForm },
  data() {
    return {
      collection: null,
      isLoading: true,
      materialApi: null,
      model: {}
    }
  },
  computed: {
    crumbs() {
      const items = [
        {
          text: this.$t('collections'),
          to: { name: 'collections-index' },
          disabled: false
        }
      ]

      if (this.collection) {
        items.push({
          text: this.collection.shortId,
          to: {
            name: 'collection-detail',
            params: { collectionId: this.collectionId }
          },
          disabled: false
        })
      }

      items.push({ text: this.$t('material'), disabled: true })
      return items
    },
    collectionId() {
      return this.$route.params.collectionId
    }
  },
  async mounted() {
    this.materialApi = materialApiBuilder.build(this.collectionId)
    this.collection = await collectionApi.fetch(this.collectionId)
    this.isLoading = false
  },
  methods: {
    ...mapActions('messages', ['addMessage', 'addError']),
    onCancel() {
      this.$router.push({
        name: 'collection-detail',
        params: { collectionId: this.collectionId }
      })
    },
    async onSave(item) {
      try {
        const response = await this.materialApi.post(item)

        this.addMessage({
          message: `${this.$t('material')} '${item.name}' ${this.$t(
            'hasBeenCreated'
          )}`
        })
        this.$router.push({
          name: 'material-detail',
          params: {
            collectionId: this.collectionId,
            materialId: response.data.id
          }
        })
      } catch (e) {
        this.addError(e)
      }
    }
  }
}
</script>

<style></style>
