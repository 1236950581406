import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import AuthService from '../services/auth.service'
import rootRoutes from './root.routes'
import collectionRoutes from './collection.routes'
import personRoutes from './person.routes'
import mapRoutes from './maps.routes'
import messageRoutes from './messaging.routes'
import packageRoutes from './package.routes'
import mediaApi from '@/services/api/media'

Vue.use(VueRouter)

let routes = []

routes = routes
  .concat(rootRoutes)
  .concat(collectionRoutes)
  .concat(personRoutes)
  .concat(mapRoutes)
  .concat(messageRoutes)
  .concat(packageRoutes)

const router = new VueRouter({
  mode: 'history',
  routes
})

router.afterEach(() => {
  mediaApi.queue.start()
})

router.beforeEach(async (to, from, next) => {
  mediaApi.queue.cancelAll() //cancel all pending media requests

  if (to.meta.authenticated) {
    const user = await AuthService.getUser()

    if (!user || user.expired) {
      window.sessionStorage.setItem('callback-path', to.path)
      window.sessionStorage.setItem('callback-path-failed', from.path)
      await AuthService.login()
    } else if (to.meta.role && !AuthService.isUserInRole(user, to.meta.role)) {
      return next(`/unauthorized?role=${to.meta.role}`)
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach(async to => {
  const title = store.state.theme.theme.title
  store.dispatch('setTitle', {
    title: to.meta.title || title
  })
})
export default router
