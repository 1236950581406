import apiClient from '@/services/api/clients/api-client'
import { API_URL } from '@/config'

const path = `${API_URL}/entityLookup`

const client = apiClient.build(path)

client.getByShortId = shortId => {
  const url = `${path}/shortId/${shortId}`
  return client.__api.get(url).then(response => response.data)
}

export default client
