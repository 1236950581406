<template>
  <div class="pt-2">
    <video
      ref="video"
      :src="dataUrl"
      :alt="value.name"
      :title="value.name"
      width="100%"
      controls
    ></video>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: null,
      default: ''
    }
  },
  computed: {
    dataUrl() {
      if (this.value.dataUrl) {
        return this.value.dataUrl
      }
      return null
    }
  },
  methods: {
    cleanup() {
      if (this.$refs.video) {
        this.$refs.video.pause()
      }
      if (this.dataUrl) {
        URL.revokeObjectURL(this.dataUrl)
      }
    }
  },
  unmounted() {
    this.cleanup()
  },
  destroyed() {
    this.cleanup()
  }
}
</script>
