<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'
import messageHub from '../hub'

export default {
  props: {
    tenantId: {
      type: String,
      required: false
    },
    profile: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      innerTenantId: null
    }
  },
  watch: {
    tenantId(val) {
      if (!val) {
        this.stop()
      } else {
        this.start()
      }
    }
  },
  created() {
    messageHub.connection.onreconnected(() => {
      this.setConnectionState(messageHub.connection.state)
    })

    messageHub.connection.on('connect', () => {
      this.setConnectionState(messageHub.connection.state)
    })

    messageHub.connection.on('userJoinedTenant', () => {
      this.setConnectionState(messageHub.connection.state)
    })

    messageHub.connection.on('messageCreated', () => {
      this.updateUnreadMessages(this.$store, { tenantId: this.tenantId })
    })

    messageHub.connection.on('channelcreated', channel => {
      this.$store.dispatch('messaging/addCreatedChannel', channel)
    })
  },
  methods: {
    ...mapActions('messaging', ['init', 'setConnectionState']),
    async start() {
      if (this.tenantId) {
        await this.init({ tenantId: this.tenantId })
        await messageHub.start(this.profile, this.tenantId)
        this.setConnectionState(messageHub.connection.state)
      }
    },
    updateUnreadMessages: _.debounce((store, payload) => {
      store.dispatch('messaging/updateUnreadMessages', {
        tenantId: payload.tenantId
      })
    }, 2000)
  }
}
</script>
