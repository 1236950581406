<template>
  <field-card :label="label">
    {{ text }}
  </field-card>
</template>

<script>
import FieldCard from '../field-card'

export default {
  components: { FieldCard },
  props: {
    label: {
      type: String,
      default: () => 'default'
    },
    text: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {}
  }
}
</script>
