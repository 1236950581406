import Index from '@/views/collection/index'
import Create from '@/views/collection/create'
import Detail from '@/views/collection/detail'
import Edit from '@/views/collection/edit'
import MaterialDetail from '@/views/material/detail'
import MaterialCreate from '@/views/material/create'
import PersonDetail from '@/views/collection/person/detail'
import PersonCreate from '@/views/collection/person/create'
import MergeCollection from '@/views/collection/merge'
import DrawingsDialog from '@/views/drawings/dialog'
import ExportDetail from '@/views/collection-exports/detail'
import ExportPdfMake from '@/views/collection/exportPdf/pdfmake'
import Messages from '@/views/collection/messages'

export default [
  {
    path: '/collections',
    name: 'collections-index',
    component: Index,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/collections/merge',
    name: 'collections-merge',
    component: MergeCollection,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/collection/new',
    name: 'collection-create',
    component: Create,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/collection/:collectionId',
    name: 'collection-detail',
    component: Detail,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/collection/:collectionId/export/pdfmake',
    name: 'collection-export-pdfmake',
    component: ExportPdfMake,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/collection/:collectionId/edit',
    name: 'collection-edit',
    component: Edit,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/collection/:collectionId/material/new',
    name: 'material-create',
    component: MaterialCreate,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/collection/:collectionId/material/:materialId',
    name: 'material-detail',
    component: MaterialDetail,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/collection/:collectionId/person/new',
    name: 'collection-person-create',
    component: PersonCreate,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/collection/:collectionId/person/:personId',
    name: 'collection-person-detail',
    component: PersonDetail,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/collection/:collectionId/drawings/:drawingsId',
    name: 'drawings-dialog',
    component: DrawingsDialog,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/collection/:collectionId/exports/:exportId',
    name: 'collection-export-detail',
    component: ExportDetail,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/collection/:collectionId/messages',
    name: 'collection-messages',
    component: Messages,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/collection/:collectionId/material/:materialId/messages',
    name: 'material-messages',
    component: Messages,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/collection/:collectionId/person/:personId/messages',
    name: 'collection-person-messages',
    component: Messages,
    meta: {
      authenticated: true
    }
  }
]
