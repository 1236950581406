<template>
  <v-card
    flat
    class="text-center h-full overflow-hidden"
    :height="height"
    :width="width"
    :max-height="maxHeight"
    :max-width="maxWidth"
  >
    <!-- <v-icon class="mr-2" color="primary" size="80"
      >mdi-file-document-outline</v-icon
    > -->
    {{ value.name }}
  </v-card>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 150
    },
    width: {
      default: '100%'
    },
    maxHeight: { default: '75px' },
    maxWidth: { default: null },
    value: {
      type: null,
      default: ''
    }
  },
  data() {
    return {
      innerValue: '',
      loading: false
    }
  }
}
</script>
