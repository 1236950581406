<template>
  <div>
    <h1>Export Collection (PDFMake)</h1>
    <div class="d-flex">
      <v-btn @click="buildPdf">Build Pdf</v-btn>
      <v-checkbox v-model="download" label="download" />
    </div>
    <v-row style="height:1000px">
      <v-col v-if="showInput">
        <v-textarea v-model="content" label="Markup" rows="550"></v-textarea>
      </v-col>
      <v-col :cols="showInput ? 6 : 12">
        <iframe :src="dataUri" width="100%" height="100%"></iframe>
      </v-col>
    </v-row>

    <!-- <pre>{{  JSON.stringify(model, null, 2) }}</pre> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import collectionApi from '@/services/api/collection'
import {
  exportCollectionDocumentDefinition,
  exportCollectionAndDownload,
  exportCollection
} from '@/services/export/pdf/pdfmake'

export default {
  data() {
    return {
      contentCode: {},
      content: '{}',
      dataUri: null,
      model: null,
      showInput: false,
      download: false
    }
  },
  computed: {
    ...mapGetters('user', ['organization']),
    id() {
      return this.$route.params.collectionId
    }
  },
  watch: {
    id(newVal) {
      this.loadCollection(newVal)
    }
  },
  mounted() {
    if (this.id) {
      this.loadCollection(this.id)
    }
  },
  methods: {
    async buildPdf() {
      try {
        const context = {
          progress: msg => {
            console.log(msg)
          },
          complete: msg => {
            console.log(msg)
          }
        }

        this.contentCode = await exportCollectionDocumentDefinition(
          this.model,
          {},
          context
        )
        //this.content = JSON.stringify(this.contentCode, null, 2)

        if (this.download) {
          exportCollectionAndDownload(this.model, {})
        } else {
          const pdf = await exportCollection(this.model, {})
          await pdf.getDataUrl(dataUri => {
            this.dataUri = dataUri
          })
        }
      } catch (e) {
        console.log('could not build PDF', e)
        this.error = e
      }
    },
    async loadCollection(id) {
      try {
        this.model = await collectionApi.fetch(id)
        this.buildPdf()
      } catch (e) {
        console.log('could not create pdf', e)
        this.error = e
      }
    }
  }
}
</script>
