const Index = () => import('@/views/maps/index')

export default [
  {
    path: '/maps/:latitude?/:longitude?',
    name: 'maps-index',
    component: Index,
    meta: {
      authenticated: true
    }
  }
]
