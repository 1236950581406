<script>
export default {
  data() {
    return {
      layout: {
        chatCols: 6,
        mainCols: 12,
        showChat: false
      }
    }
  },
  computed: {
    showChatQuery() {
      return this.$route.query.showMessages || false
    }
  },
  watch: {
    showChatQuery() {
      console.log('showchat query watch')
      this.toggleChat()
    }
  },
  methods: {
    toggleChat() {
      this.layout.showChat = !this.layout.showChat
      this.layout.mainCols = this.layout.showChat ? 7 : 12
      this.layout.chatCols = this.layout.showChat ? 5 : 0
    }
  },
  mounted() {
    console.log('mounted chat mixin', { showChatQuery: this.showChatQuery })

    if (this.showChatQuery) {
      this.toggleChat()
    }
  }
}
</script>
