<template>
  <div>
    <app-toolbar :crumbs="crumbs" />
    <v-alert v-if="errors && errors.hasError" dense text color="error">
      {{ $t('mergeError') }}
      <div v-if="errors.error">
        <ul>
          <li>{{ errors.error }}</li>
        </ul>
      </div>
    </v-alert>
    <v-row>
      <v-col md="4" sm="12">
        <v-list>
          <v-list-item-group
            v-model="sourceCollectionIndex"
            active-class="grey lighten-4"
            @change="onSourceCollectionChange"
          >
            <template v-for="(c, index) in collections">
              <collection-list-item
                :c="c"
                :key="c.id"
                :errors="errors"
              ></collection-list-item>
              <v-divider
                v-if="index < collections.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </v-col>

      <v-col md="8" sm="12">
        <div v-if="sourceCollection">
          <v-card flat tile>
            <v-alert class="text-center" text>
              <p
                v-html="
                  $t('mergeIntoMaster', {
                    number: collections.length - 1,
                    name: sourceCollection.name
                  })
                "
              ></p>
              <p class="text-left">
                <collection-list-item
                  :c="sourceCollection"
                  :key="sourceCollection.id"
                  :errors="{}"
                >
                  <template v-slot:actions><span></span></template>
                </collection-list-item>
              </p>
            </v-alert>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn plain color="default" @click="onCancel">{{
                $t('cancel')
              }}</v-btn>
              <v-btn tile large color="primary" @click="onSave">
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
        <div v-if="!sourceCollection">
          <v-alert class="text-center" text>
            <p
              v-html="$t('mergeCreateNew', { number: collections.length })"
            ></p>
          </v-alert>
          <collection-form :item="model" @cancel="onCancel" @save="onSave" />
        </div>
      </v-col>
    </v-row>

    <v-overlay :value="merging">
      <v-alert dense color="primary" class="text-center" min-width="400px">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        <div class="pt-2">
          {{ $t('collectionsMerging') }}
          <p>{{ $t('navigateDoNot') }}</p>
        </div>
      </v-alert>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import collectionApi from '@/services/api/collection'
import collectionForm from '../form'
import collectionListItem from './collection-list-item.vue'

export default {
  components: {
    appToolbar,
    collectionForm,
    collectionListItem
  },
  data() {
    return {
      crumbs: [
        { text: this.$t('collections'), to: { name: 'collections-index' } },
        { text: this.$t('merge'), disabled: true }
      ],
      sourceCollectionIndex: null,
      sourceCollection: null,
      model: {},
      collections: [],
      merging: false,
      errors: null
    }
  },
  computed: {
    ids() {
      return this.$route.query.ids || []
    }
  },
  async mounted() {
    this.loadCollections()
  },
  methods: {
    ...mapActions('messages', ['addError', 'addMessage']),
    onCancel() {
      this.$router.go(-1)
    },
    async loadCollections() {
      for (const id of this.ids) {
        await this.loadCollection(id)
      }

      this.collections.sort((a, b) => a.eventDateTime - b.eventDateTime)
      this.createNewCollection()
    },
    onSourceCollectionChange(val) {
      this.sourceCollection = val || val == 0 ? this.collections[val] : null
    },
    createNewCollection() {
      this.model = {
        ...this.collections[0],
        collectionNumber: null,
        name: `Merge::${this.collections[0].name}`
      }
      this.model.description = this.collections
        .map(c => `${c.collectionNumber}:\n${c.description}\n\n`)
        .join('')
    },
    async loadCollection(id) {
      const response = await collectionApi.fetch(id)
      this.collections.push({ ...response })
    },
    async onSave(model) {
      try {
        this.merging = true
        const data = this.sourceCollection
          ? {
            collectionIds: this.collections
              .filter(c => c.id != this.sourceCollection.id)
              .map(c => c.id),
            sourceCollectionId: this.sourceCollection.id
          }
          : {
            collectionIds: this.collections.map(c => c.id),
            eventDateTime: model.eventDateTime,
            name: model.name,
            description: model.description,
            mgrs: model.mgrs,
            latitude: model.latitude,
            longitude: model.longitude
          }

        const response = await collectionApi.merge(data)
        this.addMessage({
          message: `${data.name}: ${this.$t('hasBeenCreated')}`
        })
        this.$router.push({
          name: 'collection-detail',
          params: { collectionId: response.collection.id }
        })
      } catch (e) {
        this.errors = e
        window.scrollTo(0, 0)
        this.merging = false
      }
    }
  }
}
</script>

<style></style>
