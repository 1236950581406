<template>
  <v-app>
    <message-wrapper :tenantId="tenantId" :profile="profile">
      <v-app-bar app color="#D1D3D4" clipped-left extension-height="48">
        <v-app-bar-nav-icon @click.stop="goHome">
          <v-img
            alt="MCT Logo"
            class="shrink mr-2 ml-4"
            contain
            :src="require(`@/assets/${theme.src}`)"
            transition="scale-transition"
            width="40"
          />
        </v-app-bar-nav-icon>
        <v-toolbar-title id="title">{{ theme.title }}</v-toolbar-title>

        <v-spacer></v-spacer>
        <login-info />
        <template #extension v-if="this.$vuetify.breakpoint.smAndDown">
          <v-toolbar dark short dense flat>
            <v-app-bar-nav-icon
              color="black"
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
          </v-toolbar>
        </template>
      </v-app-bar>

      <nav-drawer v-if="isAuthenticated && !chromeless" :modelValue="drawer" />

      <v-main>
        <v-container fluid>
          <transition
            name="routerAnimation"
            enter-active-class="animated faster fadeIn"
          >
            <router-view></router-view>
          </transition>
        </v-container>
      </v-main>

      <notifications></notifications>
    </message-wrapper>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginInfo from '@/components/layout/login-info'
import NavDrawer from '@/components/layout/nav-drawer'
import Notifications from '@/components/layout/app-notifications'
import MessageWrapper from '@/messaging/components/message-wrapper.vue'

export default {
  name: 'App',

  components: {
    LoginInfo,
    MessageWrapper,
    NavDrawer,
    Notifications
  },
  data() {
    return {
      drawer: true
    }
  },
  computed: {
    ...mapGetters('user', ['isAuthenticated', 'tenantId', 'profile']),
    ...mapGetters('theme', ['theme']),
    chromeless() {
      return this.$route.meta.chromeless || this.$route.query.chromeless
    }
  },
  created() {
    this.setFavicon()
  },
  mounted() {},
  // errorCaptured() {
  //   this.snackbar = true
  //   return false
  // },
  methods: {
    goHome() {
      this.$router.push({ path: '/' })
    },
    setFavicon() {
      const favicon = document.getElementById('favicon')
      favicon.href = this.theme.favicon
    }
  }
}
</script>

<style scoped>
main div {
  height: 100%;
}

::v-deep .v-breadcrumbs__item {
  color: rgba(0, 0, 0, 0.87);
}

::v-deep .modal {
  z-index: 9999;
}

::v-deep .v-toolbar__extension {
  padding: 0 !important;
}

#title {
  color: #475569;
}

::v-deep .card-bottom-actions {
  min-width: 150px;
  min-height: 150px;
  padding-bottom: 50px;
}
::v-deep .card-actions-bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
}
</style>
