import { MESSAGING_HUB_URL } from '@/config'
import AuthService from '@/services/auth.service'
import {
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState
} from '@microsoft/signalr'

class MessageHub {
  _profile = null
  _startAttempts = 0
  _tenantId = null

  constructor() {
    this.connection = new HubConnectionBuilder()
      .withUrl(MESSAGING_HUB_URL, { accessTokenFactory: () => this.token })
      .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect()
      .build()
  }

  async start(profile, tenantId) {
    this._profile = profile
    this._tenantId = tenantId
    this._startAttempts++

    if (this.connection.state != HubConnectionState.Disconnected) {
      return
    }

    this.token = await AuthService.getAccessToken()
    if (!this.token) {
      return
    }

    await this.connection
      .start()
      .then(() => {
        console.log('Messaging connection started!')
        this.connectToTenant(this._tenantId)
      })
      .catch(err => {
        console.log('Error while establishing messaging connection :(', err)
        if (this._startAttempts < 5) {
          setTimeout(() => this.start(profile, tenantId), 5000)
        }
      })

    this.connection.onreconnected(async connectionId => {
      console.log('Messaging reconnected! ConnectionId: ', connectionId)

      this.token = await AuthService.getAccessToken()
      if (this._tenantId) {
        this.connectToTenant(this._tenantId)
      }
    })
  }

  async reconnect(profile, tenantId) {
    this._profile = profile
    this._tenantId = tenantId

    await this.connection.stop()
    await this.start(profile, tenantId)
  }

  connectToTenant(tenantId) {
    this._tenantId = tenantId

    if (tenantId) {
      this._tenantId = tenantId

      if (this.connection.state === HubConnectionState.Connected) {
        this.connection.invoke('ConnectToTenant', tenantId)
      } else {
        console.log(
          'Could not connect to tenant. Messaging connection not started, try again later',
          this.connection.state
        )
      }
    }
  }

  disconnectFromTenant(tenantId) {
    if (this._tenantId) {
      this.connection.invoke('DisconnectFromTenant', tenantId)
    }
  }
}

const hub = new MessageHub()
export default hub
