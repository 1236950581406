<template>
  <div>
    <v-overlay :value="uploading">
      <v-alert dense color="primary" class="text-center" min-width="400px">
        <div class="pt-2">
          <p>
            {{ $t('packageUploading') }}
          </p>
          <p class="text-caption">
            {{ $t('uploadLargeMsg') }}<br />
            {{ $t('navigateDoNot') }}
          </p>
        </div>
        <v-progress-linear
          color="white accent-4"
          rounded
          height="6"
          :indeterminate="!isMultipartUpload"
          :value="uploadProgress"
        ></v-progress-linear>
        <div v-if="isMultipartUpload" class="caption">
          {{ this.uploadPartsFinished }}/{{ this.uploadParts }}
        </div>
      </v-alert>
    </v-overlay>

    <v-card
      :color="color"
      hover
      @dragover="dragging = true"
      @drop="dragging = false"
      @dragleave="dragging = false"
      :class="['upload-box', dragging ? 'upload-box-active' : '']"
    >
      <form enctype="multipart/form-data" novalidate>
        <input
          type="file"
          multiple
          :name="uploadFieldName"
          :disabled="isSaving"
          @change="
            filesChange($event.target.name, $event.target.files)
            fileCount = $event.target.files.length
          "
          :accept="accept"
          class="input-file"
        />
        <v-row
          v-if="!isSaving"
          align="center"
          justify="center"
          class="fill-height pt-8"
        >
          <div class="pa-3 ">
            <v-icon x-large>mdi-cloud-upload</v-icon>
          </div>
          <div class="pa-3 text-center ">
            <small>
              {{ $t('fileExplorer') }}
            </small>
          </div>
        </v-row>
        <p v-if="isSaving">
          {{ $t('uploading') }} {{ fileCount }} {{ $t('files') }}...
        </p>
      </form>
    </v-card>
  </div>
</template>

<script>
import { ACCEPTED_FILE_TYPES } from '@/constants'
import mediaApi from '@/services/api/media'
import { fileUtils } from '@/services/file-utils.service'

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3

export default {
  components: {},
  props: {
    collectionId: { type: String, required: false },
    materialId: { type: String, required: false },
    personId: { type: String, required: false },
    accept: { type: String, default: () => ACCEPTED_FILE_TYPES }
  },
  data() {
    return {
      dragging: false,
      fileCount: 0,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'files',

      isMultipartUpload: false,
      uploading: false,
      uploadParts: 0,
      uploadPartsFinished: 0
    }
  },
  computed: {
    color() {
      return this.dragging ? 'green lighten-4' : 'grey lighten-3'
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED
    },
    uploadOptions() {
      return {
        collectionId: this.collectionId,
        materialId: this.materialId,
        personId: this.personId
      }
    },
    minMultipartUploadPartSize() {
      return fileUtils.minMultipartUploadPartSize
    },
    maxUploadSize() {
      return fileUtils.maxUploadSize
    },
    uploadProgress() {
      if (this.isMultipartUpload) {
        return Math.round((this.uploadPartsFinished / this.uploadParts) * 100)
      }
      return null
    }
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL
      this.uploadedFiles = []
      this.uploadError = null
    },
    async save(file) {
      const options = {
        ...this.uploadOptions,
        ...{
          onStartMultipart: () => {
            this.isMultipartUpload = true
          },
          onProgress: e => {
            this.uploadParts = e.parts
            this.uploadPartsFinished = e.uploaded
            console.log('onProgress', { e, percent: this.uploadProgress })
          },
          onComplete: e => {
            console.log('onComplete', e)
          }
        }
      }

      const response = await mediaApi.uploadMultipart({}, file, options)
      console.log('response', response)
      return response
    },
    async filesChange(fieldName, fileList) {
      if (!fileList.length) return

      for (let i = 0; i < fileList.length; i++) {
        const response = await this.save(fileList[i])
        this.uploadedFiles = this.uploadedFiles.concat(
          response.media || response
        )
      }

      this.currentStatus = STATUS_SUCCESS
      this.$emit('uploaded', [...this.uploadedFiles])
      this.uploadedFiles = []
    }
  },
  mounted() {
    this.reset()
  }
}
</script>

<style lang="scss" scoped>
.upload-box {
  outline: 1px dashed grey; /* the dash box */
  min-height: 150px; /* minimum height */
  position: relative;
  cursor: pointer;
}
//deep//
form {
  min-height: 150px;
}

//deep//
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100%;
  min-height: 150px;
  position: absolute;
  cursor: pointer;
}
</style>
