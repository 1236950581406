import Mgrs, { LatLon } from 'geodesy/mgrs.js'
import { Loader } from '@googlemaps/js-api-loader'
import { GOOGLE_MAPS_API_KEY } from '@/config'
import store from '@/store'

const missingMapImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII='

/* eslint-disable no-undef */
const googleMaps = function() {
  return new Promise(resolve => {
    const loader = new Loader({
      apiKey: GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places']
    })

    loader.load().then(() => {
      resolve(google.maps)
    })
  })
}

/* eslint-disable no-undef */
const getGoogleMapsImage = async function(location, zoom) {
  let latLng = ''

  if (location?.lat && location?.lng) {
    latLng = `${location.lat},${location.lng}`
  } else {
    const result = getLatLng(location.mgrs)
    latLng = `${result.latitude},${result.longitude}`
  }

  const query = `center=${latLng}&size=600x420&zoom=${zoom}&markers=size:tiny|color:red|${latLng}&key=${GOOGLE_MAPS_API_KEY}`
  const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?${query}`
  console.log('imageUrl', { imageUrl, latLng })

  const imageArrayBuffer = await fetch(imageUrl).then(res => res.arrayBuffer())
  return imageArrayBuffer
}

const getGoogleMapsImageAsDataUrl = async function(location, zoom) {
  try {
    const imageBytes = await getGoogleMapsImage(location, zoom)
    const buffer = Buffer.from(imageBytes, 'binary')
    const base64 = buffer.toString('base64')
    return `data:image/png;base64,${base64}`
  } catch (e) {
    console.log('getGoogleMapsImageAsDataUrl', e)
    return missingMapImage
  }
}

const getLatLng = function(mgrs) {
  mgrs = mgrs?.toUpperCase()
  const grid = parseInt(mgrs)
  mgrs = grid < 10 && mgrs[0] != '0' ? `0${mgrs}` : mgrs
  mgrs = mgrs.replace(/\s*/g, '')
  const p = Mgrs.parse(mgrs)
  const latlng = p.toUtm().toLatLon()
  return { latitude: latlng.lat, longitude: latlng.lng }
}

const getMgrs = function(latlng) {
  const p = new LatLon(latlng.latitude, latlng.longitude)
  return p
    .toUtm()
    .toMgrs()
    .toString(6)
    .toUpperCase()
}

const isMgrs = function(value) {
  value = value?.toUpperCase()
  value = value.replace(/\s*/g, '').replace(/^0+/, '')
  const re = /^\d{1,2}[^ABIOYZabioyz][A-Za-z]{2}([0-9][0-9])+$/
  return re.test(value)
}

const isLatitude = function(value) {
  const re = /^-?((90\/[0]{0,}\/[0]{0,}$)|([1-8]?\d))(\/|:| )(([1-5]?\d))(\/|:| )[1-5]?\d(\.\d{0,})?$/
  return re.test(value)
}

const isLongitude = function(value) {
  const re = /^-?((180(\/|:| )0(\/|:| )0((\.0{0,})?))|(([1]?[1-7]\d)|\d?\d)(\/|:| )([1-5]?\d)(\/|:| )[1-5]?\d(\.\d{0,})?$)/
  return re.test(value)
}

const isLatLong = function(value) {
  if (!value) {
    return false
  }
  try {
    return LatLon.parse(value)
  } catch {
    return false
  }
}

const browserLocation = async function() {
  return new Promise(resolve => {
    if (!('geolocation' in navigator)) {
      resolve(null)
    }

    navigator.geolocation.getCurrentPosition(
      pos => {
        resolve(pos.coords)
      },
      e => {
        console.log('browser geolocation is not available', e)
        resolve(null)
      },
      { timeout: 5000, maximumAge: 60000, enableHighAccuracy: true }
    )
  })
}

const knownLocations = {
  hawaii: {
    latitude: 21.4097967,
    longitude: -157.9160812,
    mgrs: '04Q FJ 128 584'
  },
  ukraine: {
    latitude: 50.4501,
    longitude: 30.5234,
    mgrs: '46Q JF 064 064'
  },
  niger: {
    latitude: 13.650718860017589,
    longitude: 2.058192752301693,
    mgrs: '31P DQ 557 733'
  }
}

const defaultLocation = () => {
  if (store.getters['theme/location']) {
    return { ...store.getters['theme/location'] }
  }

  return knownLocations.hawaii
}

const zoom = 10

const imagePath = '/images/maps'

export default {
  defaultLocation,
  browserLocation,
  getLatLng,
  getMgrs,
  googleMaps,
  getGoogleMapsImage,
  getGoogleMapsImageAsDataUrl,
  imagePath,
  isLatitude,
  isLongitude,
  isLatLong,
  isMgrs,
  zoom
}
