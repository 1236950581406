import Vue from 'vue'
import App from './App'
import filters from './filters'
import components from './plugins/components'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import fields from './components/fields'
import messages from './messaging/plugin'

Vue.config.productionTip = false

filters.install(Vue)
components.install(Vue)
Vue.use(fields)
Vue.use(messages)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  created: async () => {
    await store.dispatch('theme/setTheme')
    await store.dispatch('user/setUser')
  },
  render: h => h(App)
}).$mount('#app')
