<template>
  <v-badge
    :content="unreadMessageCount"
    :value="unreadMessageCount"
    color="red"
    overlap
  >
    <v-icon :size="size">mdi-message-outline</v-icon>
  </v-badge>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      channel: {},
      unreadMessageCount: 0
    }
  },
  computed: {
    ...mapGetters('messaging', ['channelByEntityId', 'unreadMessages'])
  },
  watch: {
    id(val) {
      if (val) {
        this.setChannel()
      }
    },
    unreadMessages() {
      this.setUnreadMessages()
    }
  },
  mounted() {
    this.setChannel()
    this.setUnreadMessages()
  },
  methods: {
    setChannel() {
      this.channel = this.channelByEntityId(this.id)
    },
    setUnreadMessages() {
      this.unreadMessageCount =
        this.unreadMessages.filter(x => x.channelId == this.channel?.id)
          ?.length || 0
    }
  }
}
</script>
