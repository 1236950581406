export const getComponentForContentType = (contentType, suffix) => {
  let component = 'image'

  if (contentType == 'application/pdf') {
    component = 'document'
  }

  if (contentType.startsWith('video/')) {
    component = 'video'
  }

  if (contentType.startsWith('audio/')) {
    component = 'audio'
  }

  return `mct-${component}-${suffix}`
}

export const isImage = media => {
  return media?.contentType.startsWith('image/')
}
