<template>
  <field-card :label="label" :border="border">
    <div class="field-text-area" v-html="sanitizedText"></div>
  </field-card>
</template>

<script>
import DomPurify from 'dompurify'
import marked from 'marked'

import FieldCard from '../field-card'

export default {
  components: { FieldCard },
  props: {
    border: {
      type: Boolean,
      default: () => true
    },
    label: {
      type: String,
      default: () => 'default'
    },
    text: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {}
  },
  computed: {
    sanitizedText() {
      return DomPurify.sanitize(marked.parse(this.text))
    }
  }
}
</script>

<style scoped>
.field-text-area {
  min-height: 5em;
}
</style>
