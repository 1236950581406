<template>
  <div v-if="model">
    <app-toolbar :crumbs="crumbs">
      <v-spacer></v-spacer>
    </app-toolbar>

    <v-divider class="ma-2"></v-divider>

    <div class="width-full">
      <channel-messages
        v-model="model"
        entity-type="collection"
        :toolbar="false"
        @close="toggleChat()"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import collectionApi from '@/services/api/collection'
import appToolbar from '@/components/layout/app-toolbar'
import channelMessages from '@/views/messaging/channel'
import materialApiBuilder from '@/services/api/material'
import personApiBuilder from '@/services/api/collection-person'

export default {
  components: {
    appToolbar,
    channelMessages
  },
  data() {
    return {
      collection: null,
      material: null,
      person: null,
      entityType: 'collection'
    }
  },
  computed: {
    collectionId() {
      return this.$route.params.collectionId
    },
    personId() {
      return this.$route.params.personId
    },
    materialId() {
      return this.$route.params.materialId
    },
    model() {
      if (this.material) {
        return this.material
      }

      if (this.person) {
        return this.person
      }

      return this.collection
    },
    crumbs() {
      const items = [
        { text: this.$t('collections'), to: { name: 'collections-index' } }
      ]

      if (this.collection) {
        items.push({
          text: this.collection.shortId,
          to: {
            name: 'collection-detail',
            params: { collectionId: this.collection.id }
          }
        })

        if (this.person) {
          items.push({ text: this.$t('person'), disabled: true })
          items.push({
            text: this.person.shortId,
            to: {
              name: 'collection-person-detail',
              params: {
                collectionId: this.collection.id,
                personId: this.person.id
              }
            }
          })
        }

        if (this.material) {
          items.push({ text: this.$t('material'), disabled: true })
          items.push({
            text: this.material.shortId,
            to: {
              name: 'material-detail',
              params: {
                collectionId: this.collection.id,
                materialId: this.material.id
              }
            }
          })
        }
      }

      items.push({ text: this.$t('messages'), disabled: true })

      return items
    }
  },
  watch: {
    collectionId(newVal) {
      this.loadCollection(newVal)
    },
    personId(newVal) {
      this.loadPerson(newVal)
    },
    materialId(newVal) {
      this.loadMaterial(newVal)
    }
  },
  methods: {
    ...mapActions('collections', ['select']),
    async loadCollection(id) {
      this.collection = await collectionApi.fetch(id)
      this.select({ selected: this.model })
    },
    async loadMaterial(collectionId, materialId) {
      const materialApi = materialApiBuilder.build(collectionId)
      this.material = await materialApi.fetch(materialId)
    },
    async loadPerson(collectionId, personId) {
      const personApi = personApiBuilder.build(collectionId)
      this.person = await personApi.fetch(personId)
    }
  },
  mounted() {
    if (this.collectionId) {
      this.loadCollection(this.collectionId)

      if (this.materialId) {
        this.loadMaterial(this.collectionId, this.materialId)
        this.entityType = 'material'
      }

      if (this.personId) {
        this.loadPerson(this.collectionId, this.personId)
        this.entityType = 'person'
      }
    }
  }
}
</script>
