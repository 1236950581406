<template>
  <v-card flat width-full rounded class="mb-2 pa-1" :class="cardClasses">
    <div class="d-flex flex-row flex-wrap justify-between">
      <user-avatar :id="message.createdByUserId" class="pr-2" />
      <span class="text--secondary text-no-wrapp">{{
        message.createdDateTime | dateFormat
      }}</span>
    </div>
    <v-card-text class="py-2">
      <div v-html="prettyContent"></div>
    </v-card-text>
  </v-card>
</template>

<script>
import { marked } from 'marked'
import { mapGetters } from 'vuex'
import UserAvatar from '@/components/member/avatar.vue'

import xss from 'xss'

export default {
  components: { UserAvatar },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('user', ['userId']),
    cardClasses() {
      return this.message.createdByUserId == this.userId
        ? 'ml-10 green lighten-4'
        : 'mr-10 grey lighten-4'
    },
    prettyContent() {
      const markdown = marked.parse(this.message.content)
      return xss(markdown)
    }
  }
}
</script>
