import apiClient from '@/services/api/clients/api-client.js'
import { API_URL } from '@/config'

const basePath = `${API_URL}/messaging/`

const build = function(tenantId) {
  const path = `${basePath}${tenantId}/channel`

  const client = apiClient.build(path)

  client.fetchByEntity = entityId => {
    const url = `${path}/for/${entityId}`
    return client.get(url)
  }

  client.search = model => {
    const url = `${path}/search`
    return client.__api.post(url, model)
  }

  return client
}

export default {
  build
}
