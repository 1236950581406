const Index = () => import('@/views/person/index.vue')
const Create = () => import('@/views/person/create.vue')
const Detail = () => import('@/views/person/detail/index.vue')
const Edit = () => import('@/views/person/edit.vue')
const Messages = () => import('@/views/collection/messages')

export default [
  {
    path: '/people',
    name: 'people-index',
    component: Index,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/people/new',
    name: 'person-create',
    component: Create,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/people/:personId/edit',
    name: 'person-edit',
    component: Edit,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/people/:personId',
    name: 'person-detail',
    component: Detail,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/people/:personId/messages',
    name: 'person-messages',
    component: Messages,
    meta: {
      authenticated: true
    }
  }
]
