<template>
  <div class="media-preview" v-if="innerValue || dataUrl">
    <v-hover v-slot="{ hover }">
      <v-card
        :flat="flat"
        :elevation="hover && canHover ? 12 : 0"
        :class="{ 'on-hover': hover && canHover }"
        :max-height="height"
        :max-width="width"
        :width="width"
        @click.stop="dialog = true"
      >
        <media-thumbnail
          v-model="innerValue"
          :height="height"
          :width="width"
          :max-height="maxHeight"
        />
      </v-card>
    </v-hover>

    <v-dialog v-model="dialog" v-if="dialog" fullscreen>
      <v-card height="100%">
        <v-toolbar color="primary" flat dark>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ innerValue.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <component :is="viewerComponentType" :value="innerValue" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mediaApi from '@/services/api/media'
import mediaThumbnail from './media-thumbnail'
import { getComponentForContentType } from '@/services/media.service'

export default {
  components: { mediaThumbnail },
  props: {
    canHover: {
      type: Boolean,
      default: true
    },
    flat: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 150
    },
    maxHeight: {
      type: Number,
      default: 150
    },
    width: {
      default: '100%'
    },
    value: {
      type: null,
      default: ''
    }
  },
  data() {
    return {
      dialog: false,
      innerValue: ''
    }
  },
  computed: {
    dataUrl() {
      if (this.innerValue.dataUrl) {
        return this.innerValue.dataUrl
      }
      return null
    },
    viewerComponentType() {
      return getComponentForContentType(this.innerValue.contentType, 'viewer')
    }
  },
  watch: {
    innerValue(value) {
      if (!value.dataUrl) {
        this.loadImage(value.id)
        this.$emit('input', value)
      }
    },
    value(value) {
      if (value != this.innerValue) {
        this.innerValue = value
      }
    }
  },
  methods: {
    async loadImage(itemId) {
      const response = await mediaApi.getBase64Url(itemId)
      if (response) {
        this.$set(this.innerValue, 'dataUrl', response)
      }
    },
    view() {
      window.open(this.dataUrl, '_blank')
    }
  },
  mounted() {},
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>

<style>
.media-preview .on-hover {
  cursor: pointer;
}
</style>
