<template>
  <div class="pt-2 mx-auto text-center">
    <audio
      ref="audio"
      :src="dataUrl"
      :alt="value.name"
      :title="value.name"
      width="100%"
      controls
    ></audio>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: null,
      default: ''
    }
  },
  computed: {
    dataUrl() {
      if (this.value.dataUrl) {
        return this.value.dataUrl
      }
      return null
    }
  },
  methods: {
    cleanup() {
      if (this.$refs.audio) {
        this.$refs.audio.pause()
      }
      if (this.dataUrl) {
        URL.revokeObjectURL(this.dataUrl)
      }
    }
  },
  unmounted() {
    this.cleanup()
  },
  destroyed() {
    this.cleanup()
  }
}
</script>
