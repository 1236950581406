<template>
  <v-list-item :key="c.id">
    <template v-slot:default="{ active }">
      <v-list-item-content>
        <merge-error :error="errors" :collection="c" :collectionId="c.id" />
        <v-list-item-title
          ><router-link
            :to="{
              name: 'collection-detail',
              params: { collectionId: c.id }
            }"
            target="_blank"
            >{{ c.shortId }}</router-link
          >
        </v-list-item-title>
        {{ c.collectionNumber }} <br />
        {{ c.name }}
        <div class="text-left">
          {{ c.material.length || 0 }}
          <v-icon small class="mr-2" :title="$t('material')"
            >mdi-label-multiple</v-icon
          >
          {{ c.people.length || 0 }}
          <v-icon small :title="$t('people')">mdi-account</v-icon>
          {{ c.drawings.length || 0 }}
          <v-icon small :title="$t('drawing')">mdi-drawing</v-icon>
        </div>
      </v-list-item-content>

      <slot name="actions">
        <v-list-item-action>
          <v-icon v-if="!active" color="grey lighten-1">
            mdi-square-outline
          </v-icon>

          <v-icon v-else color="primary darken-3">
            mdi-checkbox-marked-outline
          </v-icon>
        </v-list-item-action>
      </slot>
    </template>
  </v-list-item>
</template>

<script>
import mergeError from './merge-error.vue'

export default {
  components: {
    mergeError
  },
  props: {
    c: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
