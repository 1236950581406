export default {
  content: [],
  styles: {
    header: {
      fontSize: 18,
      bold: true,
      alignment: 'center',
      margin: [0, 10, 0, 10]
    },
    subheader: {
      bold: false,
      fontSize: 12,
      margin: [0, 0, 0, 4]
    },
    subheader2: {
      bold: true,
      fontSize: 12,
      margin: [0, 0, 0, 0],
      alignment: 'center'
    },
    pageFooter: {
      fontSize: 8,
      alignment: 'center',
      margin: [0, 0, 10, 15],
      opacity: 0.75
    },
    pageHeader: {
      fontSize: 8,
      margin: [0, 5, 10, 15],
      opacity: 0.75
    },
    body: {
      fontSize: 10
    },
    caption: {
      fontSize: 10,
      opacity: 0.75
    },
    fieldLabel: {
      fontSize: 8,
      bold: true,
      margin: [0, 0, 5, 2],
      alignment: 'left',
      opacity: 0.75
    },
    fieldValue: {
      fontSize: 10,
      bold: false,
      margin: [0, 0, 0, 5],
      alignment: 'left'
    }
  },
  images: {}
}
