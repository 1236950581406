<template>
  <div v-if="model && !isLoading">
    <app-toolbar :crumbs="crumbs" />

    <div>
      <errors :errors="errors" />
      <person-form
        v-model="model"
        :collection="collection"
        @cancel="onCancel"
        @save="onSave"
      ></person-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import collectionApi from '@/services/api/collection'
import personApiBuilder from '@/services/api/collection-person'
import personForm from './form'
import errors from '@/components/messages/errors'

export default {
  components: { appToolbar, personForm, errors },
  data() {
    return {
      errors: [],
      collection: null,
      isLoading: true,
      personApi: null,
      model: {}
    }
  },
  computed: {
    crumbs() {
      const items = [
        {
          text: this.$t('collections'),
          to: { name: 'collections-index' },
          disabled: false
        }
      ]

      if (this.collection) {
        items.push({
          text: this.collection.shortId,
          to: {
            name: 'collection-detail',
            params: { collectionId: this.collectionId }
          },
          disabled: false
        })
      }

      items.push({ text: this.$t('person'), disabled: true })
      return items
    },
    collectionId() {
      return this.$route.params.collectionId
    }
  },
  async mounted() {
    this.personApi = personApiBuilder.build(this.collectionId)
    this.collection = await collectionApi.fetch(this.collectionId)
    this.isLoading = false
  },
  methods: {
    ...mapActions('messages', ['addMessage', 'addError']),
    onCancel() {
      this.$router.push({
        name: 'collection-detail',
        params: { collectionId: this.collectionId }
      })
    },
    async onSave(item) {
      try {
        const response = await this.personApi.post(item)
        this.addMessage({
          message: `${this.$t('person')} '${item.name}' ${this.$t(
            'hasBeenCreated'
          )}`
        })
        this.$router.push({
          name: 'collection-person-detail',
          params: {
            collectionId: this.collectionId,
            personId: response.data.id
          }
        })
      } catch (e) {
        console.log('error', e)
        this.errors.push(e)
        this.addError(e)
      }
    }
  }
}
</script>

<style></style>
