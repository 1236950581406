const Index = () => import('@/views/messaging/index.vue')

export default [
  {
    path: '/messaging',
    name: 'messaging-index',
    component: Index,
    meta: {
      authenticated: true
    }
  }
]
