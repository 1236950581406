<template>
  <div>
    <v-alert v-if="error" prominent type="error" text>
      <v-row align="center">
        <v-col class="grow">
          <p>{{ error.title }}</p>
          <p>{{ error.detail }}</p>
        </v-col>
      </v-row>
    </v-alert>

    <div v-if="model">
      <v-alert
        v-if="model.isArchived && !model.isDeleted"
        prominent
        type="warning"
      >
        <v-row align="center">
          <v-col class="grow">
            {{ $t('collectionArchived') }}
          </v-col>
          <v-col class="shrink">
            <v-btn text @click="restoreCollection()">{{ $t('restore') }}</v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <v-alert v-if="model.isDeleted" prominent type="error">
        <v-row align="center">
          <v-col class="grow">
            {{ $t('collectionDeleted') }}
          </v-col>
        </v-row>
      </v-alert>

      <app-toolbar :crumbs="crumbs">
        <v-spacer></v-spacer>

        <v-btn
          v-if="!model.isArchived && !model.isDeleted"
          plain
          :to="{ name: 'collection-edit', params: { collectionId: model.id } }"
        >
          {{ $t('edit') }}
        </v-btn>

        <v-btn plain @click="toggleChat()" class="d-none d-md-inline ma-0 pa-0">
          <message-badge :id="id" />
        </v-btn>

        <v-btn
          plain
          class="d-md-none"
          :to="{
            name: 'collection-messages',
            params: { entityId: model.id },
            query: { chromeless: true }
          }"
          target="_blank"
        >
          <message-badge :id="id" />
        </v-btn>

        <!-- Actions -->
        <v-menu bottom left offset-y pa-3>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon small>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item-group>
              <v-list-item
                v-for="action in actions"
                :key="action.key"
                @click="selectedAction(action)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="action.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="addDrawing" class="hidden-sm-and-down">
                <v-list-item-content>
                  <v-list-item-title>{{ $t('drawingAdd') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title @click="exportCollection">{{
                    $t('exportAsZip')
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title @click="exportAsPdf">{{
                    $t('exportAsPdf')
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                v-if="!model.isArchived && !model.isDeleted"
                @click="archiveCollection"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t('archive') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="model.isArchived && !model.isDeleted"
                @click="restoreCollection"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t('restore') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="model.isArchived && !model.isDeleted"
                @click="deleteCollection"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </app-toolbar>

      <v-divider class="ma-2"></v-divider>

      <v-row>
        <v-col :cols="layout.mainCols">
          <v-container class="grow d-flex" fluid>
            <v-row class="d-flex" v-resize="onResize">
              <v-col cols="12" :md="layout.showChat ? 12 : 4">
                <v-card flat width="100%">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title class="headline mb-1">
                        {{ model.name }}
                      </v-list-item-title>
                      <div class="mt-2">
                        {{ $t('eventDate') }}:
                        {{ model.eventDateTime | dateFormat }}
                      </div>
                      <div class="mt-2" v-if="location">
                        {{ $t('location') }}:
                        <router-link :to="viewMapRoute">
                          {{ location }}</router-link
                        >
                      </div>
                      <div class="mt-2">
                        <flns-text-area
                          label=""
                          :text="model.description"
                          :border="false"
                        />
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col v-if="!layout.showChat" cols="12" md="8" justify="center">
                <map-preview
                  v-if="location"
                  :mgrs="model.mgrs"
                  :lat="model.latitude"
                  :long="model.longitude"
                  :height="this.height"
                  :width="this.width"
                  @click="viewOnMap"
                />
              </v-col>
            </v-row>
          </v-container>
          <div>
            <v-divider class="ma-2"></v-divider>

            <div class="d-flex justify-space-between">
              <h3>{{ $t('material') }}</h3>
              <v-card v-if="model" tile flat class="ml-3">
                <v-btn
                  :to="{
                    name: 'material-create',
                    params: { collectionId: id }
                  }"
                  plain
                  color="blue darken-1"
                  >{{ $t('add') }}
                </v-btn>
              </v-card>
            </div>
            <div class="pt-2">
              <material-list :items="materialSorted" :collection="model" />
            </div>

            <v-divider class="ma-2"></v-divider>

            <div class="d-flex justify-space-between">
              <h3>{{ $t('people') }}</h3>
              <v-card v-if="model" tile flat class="ml-3">
                <v-btn
                  :to="{
                    name: 'collection-person-create',
                    params: { collectionId: id }
                  }"
                  plain
                  color="blue darken-1"
                  >{{ $t('add') }}
                </v-btn>
              </v-card>
            </div>
            <div class="pt-2">
              <person-list :items="peopleSorted" :collection="model" />
            </div>
          </div>

          <v-divider class="ma-2"></v-divider>

          <div class="hidden-sm-and-down">
            <div class="d-flex justify-space-between ">
              <h3>{{ $t('drawing') }}</h3>
              <v-card v-if="model" tile flat class="ml-3">
                <v-btn @click="addDrawing" plain color="blue darken-1">{{
                  $t('add')
                }}</v-btn>
                <drawing-dialog
                  v-model="drawing"
                  :label="$t('add')"
                  :drawingDialog.sync="openDrawingDialog"
                  @save="onDrawingAdded"
                  @delete="onDrawingDelete"
                  @update="onDrawingUpdate"
                ></drawing-dialog>
              </v-card>
            </div>
            <div class="pt-2">
              <drawings-list
                :items="drawingsSorted"
                :collection="model"
                @view="viewDrawing"
                @drawing-added="onDrawingAdded"
              ></drawings-list>
            </div>
            <v-divider class="ma-2"></v-divider>
          </div>

          <div class="d-flex justify-space-between">
            <h3>{{ $t('media') }}</h3>
            <v-card v-if="model" tile flat class="ml-3">
              <collection-media
                :collectionId="id"
                @media-added="onMediaAdded"
              ></collection-media>
            </v-card>
          </div>
          <div class="pt-2">
            <v-row>
              <template v-for="(media, i) in model.media">
                <v-col :key="i" md="2" sm="4">
                  <v-card
                    height="100%"
                    width="100%"
                    class="card-bottom-actions"
                  >
                    <media-preview :value="media" :height="150"></media-preview>
                    <v-card-actions class="card-actions-bottom">
                      {{ media.shortId }}
                      <v-spacer />
                      <confirm-dialog
                        color="theme--light"
                        icon="mdi-delete-outline"
                        :title="$t('mediaDelete')"
                        :text="$t('mediaDeleteConfirm')"
                        @confirm="deleteMediaItem(media)"
                      >
                      </confirm-dialog>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </template>
            </v-row>
          </div>
          <v-divider class="ma-2"></v-divider>

          <div class="d-flex justify-space-between">
            <h3>{{ $t('exports') }}</h3>
          </div>
          <div class="pt-2">
            <export-list :collection="model" />
          </div>
        </v-col>
        <v-col v-if="layout.showChat" :cols="layout.chatCols">
          <channel-messages
            v-model="model"
            entity-type="collection"
            @close="toggleChat()"
          />
        </v-col>
      </v-row>

      <v-overlay :value="exporting">
        <v-alert dense color="primary" class="text-center" min-width="400px">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
          <div class="pt-2">
            {{ $t('packageExportCreate') }}
            <p>{{ $t('navigateDoNot') }}</p>
          </div>
        </v-alert>
      </v-overlay>

      <v-overlay :value="exportingPdf">
        <v-alert dense color="primary" class="text-center" min-width="400px">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
          <div class="pt-2">
            <span v-html="$t('packageExportCreatePdf')"></span>
          </div>
        </v-alert>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import collectionApi from '@/services/api/collection'
import mediaApi from '@/services/api/media'
import { API_URL } from '@/config'

import appToolbar from '@/components/layout/app-toolbar'
import confirmDialog from '@/components/dialogs/confirmation'
import collectionMedia from '@/views/collection/media'
import materialList from '@/views/material/list'
import personList from './person/list'
import drawingsList from '@/views/drawings/list'
import mapPreview from '@/components/maps/preview'
import drawingDialog from '@/views/drawings/dialog'
import mediaPreview from '@/components/media/media-preview'
import collectionNumber from '@/components/collection/number'
import exportList from '@/views/collection-exports/list'
import channelMessages from '@/views/messaging/channel'
import {
  exportCollection,
  exportCollectionAndDownload
} from '@/services/export/pdf/pdfmake'
import messageBadge from '@/messaging/components/message-badge'
import messageMixin from '@/components/mixins/messaging'

export default {
  mixins: [messageMixin],
  components: {
    appToolbar,
    confirmDialog,
    collectionMedia,
    personList,
    drawingsList,
    mapPreview,
    materialList,
    drawingDialog,
    mediaPreview,
    exportList,
    channelMessages,
    messageBadge
  },
  data() {
    return {
      error: null,
      model: null,
      openDrawingDialog: false,
      drawing: {},
      exporting: false,
      exportingPdf: false,
      windowSize: {
        x: 0,
        y: 0
      },
      height: 0,
      width: 0,
      layout: {
        chatCols: 6,
        mainCols: 12,
        showChat: false
      }
    }
  },
  computed: {
    actions() {
      return [
        {
          key: 'add-material',
          title: this.$t('materialCreate'),
          routeName: 'material-create'
        },
        {
          key: 'add-person',
          title: this.$t('personCreate'),
          routeName: 'collection-person-create'
        },
        /*         {
          key: 'add-drawing',
          title: 'Add Drawing',
          callback: this.addDrawing
        }, */
        {
          key: 'view-map',
          title: this.$t('mapsView'),
          routeName: 'maps-index',
          callback: this.viewOnMap
        }
      ]
    },
    id() {
      return this.$route.params.collectionId
    },
    crumbs() {
      const items = [
        { text: this.$t('collections'), to: { name: 'collections-index' } }
      ]

      if (this.model) {
        items.push({
          text: this.model.shortId,
          disabled: true
        })
        items.push({
          component: collectionNumber,
          value: this.model.collectionNumber,
          disabled: true
        })
      }

      return items
    },
    location() {
      if (this.model.mgrs) {
        return this.model.mgrs
      }

      if (this.model.latitude && this.model.longitude) {
        return `Lat: ${this.model.latitude} Long: ${this.model.longitude}`
      }

      return null
    },
    materialSorted() {
      if (!this.model.material) {
        return []
      }

      return [...this.model.material].sort((a, b) =>
        a.order > b.order ? 1 : -1
      )
    },
    peopleSorted() {
      if (!this.model.people) {
        return []
      }

      return [...this.model.people].sort((a, b) => (a.order > b.order ? 1 : -1))
    },
    drawingsSorted() {
      if (!this.model.drawings) {
        return []
      }

      return [...this.model.drawings].sort((a, b) =>
        a.order > b.order ? 1 : -1
      )
    },
    viewMapRoute() {
      return {
        name: 'maps-index',
        params: {
          collectionId: this.id,
          latitude: this.model.latitude,
          longitude: this.model.longitude
        }
      }
    }
  },
  watch: {
    id(newVal) {
      this.loadCollection(newVal)
    }
  },
  methods: {
    ...mapActions('collections', ['select']),
    ...mapActions('messages', ['addMessage']),
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      this.initialWidth = this.$vuetify.breakpoint.xs
        ? Math.ceil(this.windowSize.x * 0.7)
        : Math.ceil(this.windowSize.x * 0.45)
      this.width = this.initialWidth > 600 ? 600 : this.initialWidth
      this.height = this.$vuetify.breakpoint.xs
        ? Math.ceil(this.width)
        : Math.ceil(this.width * 0.7)
    },
    async loadCollection(id) {
      try {
        this.model = await collectionApi.fetch(id)
        this.select({ selected: this.model })
      } catch (e) {
        console.log('could not fetch collection', e)
        this.error = e
      }
    },
    close() {
      this.$router.back()
    },
    getMediaUrl(media) {
      return `${API_URL}/media/${media.id}`
    },
    selectedAction(action) {
      if (action.callback) {
        return action.callback()
      }

      if (action.routeName) {
        this.$router.push({
          name: action.routeName,
          params: { collectionId: this.id }
        })
      }
    },
    viewOnMap() {
      this.$router.push(this.viewMapRoute)
    },
    addDrawing() {
      this.drawing = {}
      this.openDrawingDialog = true
    },
    viewDrawing(item) {
      this.drawing = item
      this.openDrawingDialog = true
    },
    onDrawingAdded(item) {
      this.model.drawings.push(item)
    },
    onDrawingDelete(item) {
      const index = this.model.drawings.findIndex(x => x.id === item.id)
      this.model.drawings.splice(index, 1)
    },
    onDrawingUpdate(item) {
      const index = this.model.drawings.findIndex(x => x.id === item.id)
      this.model.drawings.splice(index, 1, item)
    },
    onMediaAdded(files) {
      this.model.media.push(...files)
    },
    async deleteMediaItem(mediaItem) {
      console.log('delete media item', mediaItem)
      await mediaApi.delete(mediaItem.id, { collectionId: this.id })
      this.model.media.splice(this.model.media.indexOf(mediaItem), 1)
    },
    async archiveCollection() {
      await collectionApi.archive(this.id)
      this.model.isArchived = true
    },
    async restoreCollection() {
      await collectionApi.restore(this.id)
      this.model.isArchived = false
    },
    async deleteCollection() {
      await collectionApi.archive(this.id)
      this.model.isDeleted = true
    },
    async exportAsPdf() {
      try {
        this.exportingPdf = true
        const options = {}
        try {
          await exportCollectionAndDownload(this.model, options)
          this.addMessage({ message: `${this.$t('downloadPdfComplete')}` })
        } catch (error) {
          console.log('could not download pdf', error)
          const doc = await exportCollection(this.model, options)
          doc.open()
          this.addMessage({ message: `${this.$t('downloadPdfComplete')}` })
        }
      } catch (error) {
        this.error = {
          title: this.$t('collectionExportError'),
          detail: error
        }
      } finally {
        this.exportingPdf = false
      }
    },
    async exportCollection() {
      try {
        this.exporting = true
        const response = await collectionApi.export(this.id)
        window.open(response.url)
      } catch (error) {
        console.log('error', error)
        this.addError({ message: this.$t('collectionExportError'), error })
      } finally {
        this.exporting = false
      }
    }
    // toggleChat() {
    //   this.layout.showChat = !this.layout.showChat
    //   this.layout.mainCols = this.layout.showChat ? 7 : 12
    //   this.layout.chatCols = this.layout.showChat ? 5 : 0
    // }
  },
  mounted() {
    this.onResize()
    if (this.id) {
      this.loadCollection(this.id)
    }
  }
}
</script>

<style scoped></style>
