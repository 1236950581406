import axios from 'axios'
import router from '@/router'
import store from '@/store'
import AuthService from '@/services/auth.service'
import { API_URL } from '@/config'

const api = axios.create({
  baseURL: `${API_URL}`
})

const configureRequest = function(config) {
  return AuthService.getAccessToken().then(token => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return Promise.resolve(config)
  })
}

const reLogin = async () => {
  await AuthService.login(router.currentRoute.path)
}

const handle401 = async err => {
  if (!err.response || err.response.status !== 401) {
    return
  }

  if (err.config && err.config._isRetry) {
    await reLogin()
  }

  try {
    let user = await AuthService.getUser()

    if (user && user.expired) {
      user = await AuthService.refresh()
    }

    if (user) {
      err.config._isRetry = true
      await api(err.config)
    } else {
      await reLogin()
    }
  } catch (err) {
    await reLogin()
  }
}

const handleResponseErrors = err => {
  if (err.response) {
    switch (err.response.status) {
      case 500:
        store.dispatch('messages/addError', {
          severity: 'danger',
          message: 'Api error',
          error: err.response
        })
        break
      case 403:
        store.dispatch('messages/addError', {
          severity: 'danger',
          message: 'Forbidden',
          error: err.response
        })
        break
    }
  } else if (err.request) {
    if (localStorage) {
      localStorage.requestError = err
    }
    store.dispatch('messages/addError', {
      severity: 'danger',
      message: 'Api unavailable',
      error: err
    })
  }
}

api.interceptors.request.use(configureRequest, err => {
  return Promise.reject(err)
})

api.interceptors.response.use(undefined, err => {
  //console.log('err.request', err)
  handleResponseErrors(err)
  handle401(err)
  const reason = err.response ? err.response.data : err
  return Promise.reject({ ...reason })
})

api.entityPath = function(path, id) {
  return `${path}/${id}`
}

api.build = function(p) {
  const path = p

  const del = (id, suffix) => {
    const url = `${api.entityPath(path, id)}/${suffix || ''}`
    return api.delete(url).then(response => response.data)
  }

  const fetch = id => {
    return api.get(api.entityPath(path, id)).then(response => response.data)
  }

  const get = (url, params) => {
    return api.get(url || path, params).then(response => {
      let paging = {
        hasNextPage: false
      }

      if (response.data.paging) {
        paging = response.data.paging
      }

      return {
        paging: paging,
        data: response.data
      }
    })
  }

  const post = entity => {
    return api.post(path, entity)
  }

  const put = (id, entity) => {
    return api
      .put(api.entityPath(path, id), entity)
      .then(response => response.data)
  }

  return {
    __api: api,
    delete: del,
    fetch,
    get,
    post,
    put
  }
}

export default api
