<template>
  <v-card flat :style="toolbar ? 'border-left: solid thin lightgrey' : ''">
    <v-system-bar v-if="toolbar" height="35">
      <span class="text-lg-h5">Messages</span>
      <v-spacer></v-spacer>

      <v-btn
        v-if="model"
        icon
        :to="{
          name: detailRoute,
          params: detailRouteParams,
          query: { chromeless: true }
        }"
        target="_blank"
      >
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      <v-btn icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-system-bar>
    <v-divider v-if="toolbar" />

    <div v-if="channel && channel.id && isConnected">
      <ChannelMessages :channel-id="channel.id" :toolbar="toolbar" />
    </div>
    <div v-if="!isConnected">
      <v-alert type="error">{{ $t('messagingDisconnected') }}</v-alert>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { channelApiBuilder } from '@/messaging/api'
import ChannelMessages from '@/messaging/components/channel/messages.vue'

export default {
  components: {
    ChannelMessages
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    entityType: {
      type: String,
      required: true
    },
    toolbar: {
      type: Boolean,
      default: true
    },
    detailRoute: {
      type: String,
      default: 'collection-messages'
    }
  },
  data() {
    return {
      model: null,
      channel: null,
      channelApi: null
    }
  },
  computed: {
    ...mapGetters({
      tenantId: 'user/tenantId'
    }),
    ...mapGetters('messaging', ['isConnected']),
    detailRouteParams() {
      return {
        entityId: this.model.id
      }
    }
  },
  watch: {
    value(val) {
      this.model = val
      this.fetchChannel()
    }
  },
  mounted() {
    this.model = this.value
    this.fetchChannel()
  },
  methods: {
    async ensureApi() {
      if (!this.channelApi) {
        this.channelApi = channelApiBuilder.build(this.tenantId)
      }
      return this.channelApi
    },
    async fetchChannel() {
      if (!this.model || !this.model.id) {
        return
      }

      try {
        console.log('fetching channel for', this.model.id)
        await this.ensureApi()

        const response = await this.channelApi.fetchByEntity(this.model.id)
        this.channel = response.data
      } catch (e) {
        if (e.status == 404) {
          console.log('creating channel')
          this.createChannel()
        } else {
          throw e
        }
      }
    },
    async createChannel() {
      const payload = {
        tenantId: this.tenantId,
        entityId: this.model.id,
        entityType: this.entityType,
        name: this.model.shortId ?? this.model.name
      }

      const response = await this.channelApi.post(payload)
      this.channel = response.data
    },
    close() {
      this.$emit('close', null)
    }
  }
}
</script>
