export const safeText = text => {
  return text || ''
}

/*
 * Field label and value
 */
export const field = (label, value, style) => {
  style = style || 'fieldValue'
  return {
    stack: [
      { text: safeText(label), style: 'fieldLabel' },
      { text: safeText(value) }
    ],
    style: style
  }
}

/*
 * Content wrapped in a table to provide a border around the content
 */
export const contentWithBorder = (content, padding, borderColor) => {
  padding = padding || 0
  borderColor = borderColor || 'gray'

  const paddingFunc = () => {
    return padding
  }
  const borderColorFunc = () => {
    return borderColor
  }

  return {
    table: {
      widths: '*',
      body: [[content]]
    },
    layout: {
      paddingLeft: paddingFunc,
      paddingRight: paddingFunc,
      paddingTop: paddingFunc,
      paddingBottom: paddingFunc,
      hLineColor: borderColorFunc,
      vLineColor: borderColorFunc
    }
  }
}
