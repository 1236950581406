<template>
  <div class="media-thumbnail" v-if="dataUrl" v-bind:style="containerStyle">
    <v-card :loading="loading" class="mx-auto" flat>
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <template v-if="componentType">
        <component
          :is="componentType"
          :value="innerValue"
          :height="height"
          :width="width"
          :max-height="maxHeight"
          :max-width="maxWidth"
        />
      </template>
    </v-card>
  </div>
</template>

<script>
import mediaApi from '@/services/api/media'
import { getComponentForContentType } from '@/services/media.service'

export default {
  props: {
    height: {
      type: Number,
      default: 150
    },
    width: {
      default: '100%'
    },
    maxHeight: { default: '75px' },
    maxWidth: { default: null },
    value: {
      type: null,
      default: ''
    }
  },
  data() {
    return {
      innerValue: '',
      loading: false
    }
  },
  computed: {
    dataUrl() {
      if (this.innerValue.dataUrl) {
        return this.innerValue.dataUrl
      }
      return null
    },
    containerStyle() {
      return {
        'max-height': this.maxHeight,
        'max-width': this.maxWidth
      }
    },
    componentType() {
      return getComponentForContentType(this.value.contentType, 'thumbnail')
      // if(this.value.contentType.startsWith('image')){
      //   return 'mct-image-thumbnail'
      // }
      // if(this.value.contentType == 'application/pdf'){
      //   return 'mct-document-thumbnail'
      // }
      // return null
    }
  },
  watch: {
    innerValue(value) {
      if (!value.dataUrl) {
        this.loadImage(value.id)
        this.$emit('input', value)
      }
    },
    value(val) {
      if (val != this.innerValue) {
        this.innerValue = val
      }
    }
  },
  methods: {
    async loadImage(itemId) {
      this.loading = true
      const response = await mediaApi.getBase64Url(itemId)
      if (response) {
        this.$set(this.innerValue, 'dataUrl', response)
      }
      this.loading = false
    }
  },
  mounted() {},
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>

<style>
.media-thumbnail {
  min-height: 40px;
  min-width: 40px;
  width: 100%;
  height: 100%;
}
.media-thumbnail .on-hover {
  cursor: pointer;
}
</style>
