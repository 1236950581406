import {
  MAX_PACKAGE_FILE_SIZE_MB,
  MIN_MULTIPART_UPLOAD_PART_SIZE_MB
} from '@/config'

export const fileUtils = {
  minMultipartUploadPartSize: MIN_MULTIPART_UPLOAD_PART_SIZE_MB,

  minMultipartUploadPartSizeBytes:
    MIN_MULTIPART_UPLOAD_PART_SIZE_MB * 1024 * 1024,

  maxUploadSize: MAX_PACKAGE_FILE_SIZE_MB,

  isValidUploadSize: file => {
    return file.size <= MAX_PACKAGE_FILE_SIZE_MB * 1024 * 1024
  },

  fileBytesToMB: file => {
    const size = file.size / (1024 * 1024)
    return size.toFixed(2)
  },

  splitArrayBuffer: (arrayBuffer, chunkSize) => {
    const chunks = Math.ceil(arrayBuffer.byteLength / chunkSize)
    const result = []

    for (let i = 0; i < chunks; i++) {
      const start = i * chunkSize
      const end = Math.min(start + chunkSize, arrayBuffer.byteLength)
      const chunk = new Uint8Array(arrayBuffer.slice(start, end))
      result.push(chunk)
    }

    return result
  },

  arrayBufferFromDataURI: dataURI => {
    const base64 = dataURI.split(',')[1]
    const binary = atob(base64)
    const length = binary.length
    const arrayBuffer = new ArrayBuffer(length)
    const uint8Array = new Uint8Array(arrayBuffer)

    for (let i = 0; i < length; i++) {
      uint8Array[i] = binary.charCodeAt(i)
    }

    return arrayBuffer
  }
}
