<template>
  <v-alert v-if="hasError" color="error" dense text>
    <h4>{{ collection.collectionNumber }}</h4>

    <h5 v-if="materialErrors.length">{{ $t('materialErrors') }}s</h5>
    <div v-for="m in materialErrors" :key="m.id" class="pl-4">
      <div class="text-decoration-underline">{{ m.name }}</div>
      <div v-if="m.error" class="pl-2">{{ $t('errors') }}: {{ m.error }}</div>
      <div v-for="e in m.media" :key="e.id" class="pl-4">
        <div v-if="e.hasError">
          {{ $t('media') }}: {{ e.fileName }}:: {{ e.error }}
        </div>
      </div>
    </div>

    <h5 v-if="peopleErrors.length">{{ $t('peopleErrors') }}</h5>
    <div v-for="p in peopleErrors" :key="p.id" class="pl-4">
      <div class="text-decoration-underline">{{ p.name }}</div>
      <div v-if="p.error" class="pl-2">{{ $t('errors') }}: {{ p.error }}</div>
      <div v-for="e in p.media" :key="e.id" class="pl-4">
        <div v-if="e.hasError">
          {{ $t('media') }}: {{ e.fileName }}:: {{ e.error }}
        </div>
      </div>
    </div>

    <h5 v-if="drawingErrors.length">{{ $t('drawingErrors') }}</h5>
    <div v-for="d in drawingErrors" :key="d.id" class="pl-4">
      <div class="text-decoration-underline">{{ d.name }}</div>
      <div v-if="d.error" class="pl-2">{{ $t('errors') }}: {{ d.error }}</div>
    </div>

    <h5 v-if="mediaErrors.length">{{ $t('collectionMediaErrors') }}</h5>
    <div v-for="d in mediaErrors" :key="d.id" class="pl-4">
      <div class="text-decoration-underline">{{ d.name }}</div>
      <div v-if="d.error" class="pl-2">{{ $t('errors') }}: {{ d.error }}</div>
    </div>
  </v-alert>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: () => {}
    },
    collectionId: {
      type: String,
      required: true
    },
    collection: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    collectionResponse() {
      if (!this.error || !this.error.collections) {
        return []
      }

      return this.error.collections.find(e => e.id === this.collectionId)
    },
    hasError() {
      return this.collectionResponse && this.collectionResponse.hasError
    },
    materialErrors() {
      return this.collectionResponse?.material.filter(e => e.hasError) || []
    },
    peopleErrors() {
      return this.collectionResponse?.people.filter(e => e.hasError) || []
    },
    drawingErrors() {
      return this.collectionResponse?.drawings.filter(e => e.hasError) || []
    },
    mediaErrors() {
      return this.collectionResponse?.media.filter(e => e.hasError) || []
    }
  }
}
</script>
