<template>
  <div class="d-flex flex-column field-card" :class="fieldClass">
    <label class="text-caption"> {{ label }}</label>
    <div class="border-bottom-1">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: () => 'default'
    },
    border: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {}
  },
  computed: {
    fieldClass() {
      return this.border ? 'field-card-border' : ''
    }
  }
}
</script>

<style>
.field-card {
  padding-top: 12px;
  margin-top: 4px;
}
.field-card-border {
  border-bottom: 1px solid #ccc;
}
</style>
