<script>
import collectionApi from '@/services/api/collection'
export default {
  data() {
    return {
      collection: null
    }
  },
  computed: {
    baseCrumbs() {
      const items = [
        { text: this.$t('collections'), to: { name: 'collections-index' } },
        {
          text: this.shortId,
          to: {
            name: 'collection-detail',
            params: { collectionId: this.collectionId }
          },
          disabled: false
        }
      ]

      return items
    },
    collectionId() {
      return this.$route.params.collectionId
    },
    collectionNumber() {
      if (!this.collection) {
        return ''
      }

      return this.collection.collectionNumber
    }
  },
  watch: {
    collectionId(newVal) {
      this.loadCollection(newVal)
    }
  },
  mounted() {
    if (this.collectionId) {
      this.loadCollection(this.collectionId)
    }
  },
  methods: {
    async loadCollection(id) {
      this.collection = await collectionApi.fetch(id)
    }
  }
}
</script>
