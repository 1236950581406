<script>
import { IDENTITY_SERVER_URL } from '@/config'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', [
      'organization',
      'profile',
      'isInRole',
      'isAuthenticated',
      'userId',
      'tenantId'
    ]),
    isOrgAdmin() {
      return this.isInRole('orgadmin')
    },
    isSysAdmin() {
      return this.isInRole('sysadmin')
    },
    profileLink() {
      return `${IDENTITY_SERVER_URL}/manage/index`
    }
  }
}
</script>
